.form {
  .button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    .submit_button
    {
      margin: 5px;
    }
  }
  .error_message {
    color: red
  }
}